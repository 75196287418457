import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { Box } from '@chakra-ui/react'
import { isMobile } from 'react-device-detect'
import configClient from '../../../../../../client/config.client'

/* polyfills */
import 'core-js'
import 'whatwg-fetch'
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch'
// Order matters: AbortController needs fetch which needs Promise (provided by core-js).
import 'md-gum-polyfill'
import ResizeObserver from 'resize-observer-polyfill'
if (window.ResizeObserver === undefined || window.ResizeObserver === null) {
	window.ResizeObserver = ResizeObserver
}

/** Import Uppy Libraries and React-specific components */
import { Dashboard } from '@uppy/react'

/** Import Vidday Libs */
import { logGTMTracking } from '../utils'
import { postTrack } from '../../../../../api/app/media'
import { addFlashMessage } from '../../../../../api/app/behaviors/flash-messages'

/** Import Custom Hooks */
import useUppy from '../hooks/useUppy'
import useContributorId from '../hooks/useContributorId'
import useGroupMediaUUID from '../hooks/useGroupMediaUUID'
import usePlugins from '../hooks/usePlugins'

/** Import Styles (including those related to each Plugins) */
import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'
import '@uppy/audio/dist/style.css'
import '@uppy/webcam/dist/style.css'
import './uppy.overwrite.css'

import { useSearchParams } from 'react-router-dom'
import { useAuthContext } from '../../../../contexts/AuthProvider'
import randomBytes from 'randombytes'

const screenMessage = ''

const UploaderTrack = ({
	// The maximum amount of files that can be uploaded
	maxNumberOfFiles,
	allowedFileTypes,
	handleClose,
	event,
	onUpload,
}) => {
	console.log(allowedFileTypes, 'allowedFileTypes')
	const { id, uuid } = event
	const { userId } = useAuthContext()
	const [searchParams] = useSearchParams()
	const dispatch = useDispatch()
	const contributorId = useContributorId()
	const groupMediaUuid = useGroupMediaUUID()
	const plugins = usePlugins(['Audio'], ['Audio'])

	const uppy = useUppy(uuid, {
		id: 'upload-track',
		autoProceed: true,
		restrictions: {
			maxNumberOfFiles: maxNumberOfFiles,
			allowedFileTypes: allowedFileTypes,
		},
		meta: {
			eventId: id,
			eventUuid: uuid,
			uploadUuid: groupMediaUuid,
		},
		/**
		 * A function run before an upload begins. Gets passed files object with all the files that are already in Uppy.
		 * Use this to check if all files or their total number match your requirements, or manipulate all the files at once before upload.
		 * @param {*} files
		 */
		onBeforeUpload: (files) => {
			uppy.log(`Files to be uploaded: ${JSON.stringify(files)}`)

			const updatedFiles = Object.assign({}, files)
			// const userToken = searchParams.get('user') // probably not needed anymore

			Object.keys(updatedFiles).forEach((fileId) => {
				const mediaUuid = randomBytes(16).toString('hex')
				const dateString = Date.now()
				const extraMeta = `` //customEvent ? `_${userToken}` : `` // probably not needed anymore

				const baseName = `${mediaUuid}_${dateString}${extraMeta}`
				const name = `tracks/${userId}/${baseName}.${updatedFiles[fileId].extension.replace('&ct=g', '')}`

				updatedFiles[fileId].name = name
				updatedFiles[fileId].meta.mediaUuid = mediaUuid
				updatedFiles[fileId].meta.name = name
				updatedFiles[fileId].meta.storeName = baseName
				updatedFiles[fileId].extension = `${updatedFiles[fileId].extension.replace('&ct=g', '')}`
			})

			return updatedFiles
		},
	})

	/**
	 * Method to handle the Store Upload
	 * @param {*} file
	 */
	const handleStoreUpload = (file, response) => {
		let upload = {}

		// If song was recorded at upload, data name will be unavailable
		if (file.data.name) {
			upload.label = file.data.name
		} else {
			upload.label = `Recording for ${file.meta.eventUuid}`
		}

		/** Check if this is TUS upload */
		if (response.uploadURL) {
			const uploadId = response.uploadURL.split('/files/')[1]?.split('+')[0] // upload id is portion between "/files/" and "+"
			upload.uploadKey = `${configClient.uppy.tusPrefix}${uploadId}` // tus upload key
		}

		upload.sourceKey = file.name
		upload.eventId = id

		console.log(upload, 'handle store upload')
		/** Post media */
		dispatch(postTrack(upload))
	}

	useEffect(() => {
		if (uppy) {
			uppy.on('file-removed', (file, reason) => {
				if (reason == 'removed-by-user') {
					console.log('File :', file.id, ' removed by user.')
				}
			})

			uppy.on('upload', ({ id, fileIDs }) => {
				// data object consists of `id` with upload ID and `fileIDs` array
				// with file IDs in current upload
				uppy.log(`Starting upload ${id} for files ${fileIDs}`)

				// Callback event to let the rest of the app we're currently uploading
				onUpload && onUpload(true)
			})

			uppy.on('upload-success', (data, response) => {
				/* Detect 0 byte files */
				if (data.size > 0 || data.isRemote == true) {
					/* Detected non-zero byte file, proceed */
					handleStoreUpload(data, response) // Add file to database and begin processing

					uppy.removeFile(data.id)
					uppy.info(`${data.data.name || data.name} - uploaded!`, 'success', 6000)

					// GTM Tracking
					// What media are we handling most?
					logGTMTracking(data)
				} else {
					/* Detected 0 byte file, reject */
					const error = new Error('Uploaded file is 0 bytes. Please retry.')
					// Emit error for this file
					uppy.emit('upload-error', data, error, response)
				}

				// Should we shut things down?
				let remainingFiles = uppy.getFiles()
				if (remainingFiles.length <= 0) {
					handleClose()
				}
			})

			uppy.on('error', (err) => {
				// When entire upload fails
				uppy.log('on ERROR : An error occured :', 'error')
				console.error(`on ERROR : An error occured : ${err.message}`)
			})

			uppy.on('upload-error', (file, error, response) => {
				// Fired each time a single upload has errored.
				console.error('Error with file : ', file.id)
				console.error('Error reason : ', error)
				console.error('Error Response from Companion : ', response)

				// Do other things here, notify user, highlight failed file in red, etc..
				//dispatch(addFlashMessage('error', `${file.data.name} - failed.`)) // notify the user this one is safe
			})
		}

		return () => uppy.close({ reason: 'unmount' })
	}, [uppy])

	return (
		<Box w="full">
			<Dashboard
				uppy={uppy}
				plugins={plugins}
				locale={{
					strings: {
						// Used as the label for the link that opens the system file selection dialog.
						browse: isMobile ? 'CAMERA OR LIBRARY' : 'SELECT FROM COMPUTER',
						myDevice: isMobile ? `Camera or Files` : `Upload`,
						done: 'Cancel',
						// Text to show on the droppable area.
						dropPasteFiles: screenMessage,
						dropPasteFolders: screenMessage,
						dropPasteBoth: screenMessage,
						dropPasteImportFiles: screenMessage,
						dropPasteImportFolders: screenMessage,
						dropPasteImportBoth: screenMessage,
						dropHint: screenMessage,
						dropHereOr: screenMessage,
						dropPaste: screenMessage,
						dropPasteImport: screenMessage,
					},
				}}
			/>
		</Box>
	)
}

UploaderTrack.defaultProps = {
	maxNumberOfFiles: 1,
	allowedFileTypes: [
		'.mp3',
		'.aac',
		'.flak',
		'.wav',
		'.ogg',
		'.aiff',
		'.dsd',
		'.pcm',
		'.wma',
		'.weba',
		'.caf',
		'audio/webm',
		'audio/mp4',
		'audio/mpeg',
	],
	handleClose: undefined,
	event: undefined,
	onUpload: undefined,
}

UploaderTrack.propTypes = {
	maxNumberOfFiles: PropTypes.number,
	handleClose: PropTypes.func.isRequired,
	onUpload: PropTypes.func,
	event: PropTypes.object.isRequired,
}

export default UploaderTrack
