import React, { useState, useEffect } from 'react'
import merge from 'lodash.merge'
import { getCleanFileName, getSource } from '../utils'
import randomBytes from 'randombytes'
import { uppy as uppyConfig } from '../../../../../../client/config.client'

/** Import Uppy Libraries and React-specific components */
import Uppy from '@uppy/core'
import Webcam from '@uppy/webcam'
import Audio from '@uppy/audio'
import Tus from '@uppy/tus'
import GoogleDrive from '@uppy/google-drive'
import Dropbox from '@uppy/dropbox'
import Url from '@uppy/url'

const companionUrl = uppyConfig.companionUrl // Retrieve uppy's companion url
const tusEndpoint = uppyConfig.tusEndpoint

const defaultConfig = {
	autoProceed: false,
	allowMultipleUploadBatches: true,
	debug: true,
	infoTimeout: 7000,
	restrictions: {
		maxFileSize: 1000000000, // 1Gb
		minNumberOfFiles: null,
	},
}

/**
 * Pass the uuid of the event as the first argument so we can attach the event to the files being uploaded.
 * Pass a configuration object as the second param
 * @param {string} uuid
 * @param {object} customConfig
 * @returns
 */
const useUppy = (uuid, customConfig) => {
	const uppy = React.useMemo(() => {
		return (
			new Uppy(
				merge(
					{},
					defaultConfig,
					{
						/**
						 * A function run before a file is added to Uppy. It gets passed (currentFile, files) where currentFile is a file that is about to be added, and files is an object with all files that already are in Uppy.
						 * Use this function to run any number of custom checks on the selected file, or manipulate it, for instance, by optimizing a file name.
						 * @param {*} currentFile
						 * @param {*} files
						 */
						onBeforeFileAdded: (currentFile, files) => {
							/** Inform logger of current file */
							uppy.log(`Adding file: ${currentFile.name}. Proceed to cleaning space(s).`)

							/** Obtain a copy of the file where spaces have been stripped */
							const modifiedFile = getCleanFileName(currentFile)

							/** Proceed with upload -> if file was handled By Pintura */
							uppy.log(`Proceed with uploaded of the modified file ${modifiedFile.name}`)
							return modifiedFile
						},

						/**
						 * A function run before an upload begins. Gets passed files object with all the files that are already in Uppy.
						 * Use this to check if all files or their total number match your requirements, or manipulate all the files at once before upload.
						 * @param {*} files
						 */
						onBeforeUpload: (files) => {
							uppy.log(`Files to be uploaded: ${JSON.stringify(files)}`)

							const updatedFiles = Object.assign({}, files)
							// const userToken = searchParams.get('user') // probably not needed anymore

							Object.keys(updatedFiles).forEach((fileId) => {
								const mediaUuid = randomBytes(16).toString('hex')
								const dateString = Date.now()

								const baseName = `${mediaUuid}_${dateString}`
								let nameExt =
									updatedFiles[fileId].extension != '' ? `.${updatedFiles[fileId].extension}` : ''

								// force .jpg extension on Unsplash images
								if (getSource(updatedFiles[fileId]) == 'Unsplash') {
									nameExt = '.jpg'
									updatedFiles[fileId].extension = 'jpg'
								}

								// fix Gif weirdness with file extensions have extra stuff
								if (getSource(updatedFiles[fileId]) == 'Gif') {
									updatedFiles[fileId].extension = updatedFiles[fileId].extension.split('&')[0]
									updatedFiles[fileId].name = updatedFiles[fileId].name.split('&')[0]
									updatedFiles[fileId].meta.name = updatedFiles[fileId].meta.name.split('&')[0]
								}

								const name = `${uuid}/${baseName}${nameExt}`

								updatedFiles[fileId].name = name
								updatedFiles[fileId].meta.mediaUuid = mediaUuid
								updatedFiles[fileId].meta.name = name
								updatedFiles[fileId].meta.storeName = baseName
							})

							return updatedFiles
						},
					},
					customConfig
				)
			)
				/**
				 * Using TUS as upload backend instead of AwsS3Multipart now!
				 */
				.use(Tus, {
					endpoint: tusEndpoint,
					removeFingerprintOnSuccess: true, // do not keep references to previously uplaoded files in local storage
				})
				/**
				 * Webcam
				 */
				.use(Webcam, {
					modes: ['video-audio'],
					title: 'Record',
					mirror: true,
					showRecordingLength: true,
					countdown: true,
					preferredVideoMimeType: 'video/webm',
					videoConstraints: {
						facingMode: 'user',
						width: { min: 640, ideal: 1920, max: 1920 },
						height: { min: 360, ideal: 1080, max: 1080 },
					},
					locale: {
						strings: {
							smile: 'Click here to start recording.',
							// Used as the label for the recording length counter. See the showRecordingLength option.
							// This is not visibly rendered but is picked up by screen readers.
							recordingLength: 'Recording length %{recording_length}',
						},
					},
				})
				/**
				 * Audio
				 */
				.use(Audio, {
					id: 'Audio',
					// target: null,
					showAudioSourceDropdown: true,
					locale: {
						strings: {
							pluginNameAudio: 'Audio',
							// Used as the label for the button that starts an audio recording.
							// This is not visibly rendered but is picked up by screen readers.
							startAudioRecording: 'Begin audio recording',
							// Used as the label for the button that stops an audio recording.
							// This is not visibly rendered but is picked up by screen readers.
							stopAudioRecording: 'Stop audio recording',
							// Title on the “allow access” screen
							allowAudioAccessTitle: 'Please allow access to your microphone',
							// Description on the “allow access” screen
							allowAudioAccessDescription:
								'In order to record audio, please allow microphone access for this site.',
							// Title on the “device not available” screen
							noAudioTitle: 'Microphone Not Available',
							// Description on the “device not available” screen
							noAudioDescription:
								'In order to record audio, please connect a microphone or another audio input device',
							// Message about file size will be shown in an Informer bubble
							recordingStoppedMaxSize:
								'Recording stopped because the file size is about to exceed the limit',
							// Used as the label for the counter that shows recording length (`1:25`).
							// This is not visibly rendered but is picked up by screen readers.
							recordingLength: 'Recording length %{recording_length}',
							// Used as the label for the submit checkmark button.
							// This is not visibly rendered but is picked up by screen readers.
							submitRecordedFile: 'Submit recorded file',
							// Used as the label for the discard cross button.
							// This is not visibly rendered but is picked up by screen readers.
							discardRecordedFile: 'Discard recorded file',
						},
					},
				})
				/**
				 * Google Drive
				 */
				.use(GoogleDrive, { companionUrl: companionUrl })
				/**
				 * Dropbox
				 */
				.use(Dropbox, { companionUrl: companionUrl })
				/**
				 * URL path
				 */
				.use(Url, { companionUrl: companionUrl })
		)
	}, [])

	return uppy
}

export default useUppy
