import React from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useEventContext } from '../../../contexts/EventProvider'
import NavBar from '../../atoms/NavBar'
import { HStack, Spinner } from '@chakra-ui/react'
import BackButton from '../../atoms/nav-controls/BackButton'
import { Box, Heading, Container, Text } from '@chakra-ui/react'
import Card from '../../atoms/Card'
import FooterBase from '../../molecules/FooterBase'
import useScrollTopOnMount from '../../../hooks/useScrollTopOnMount'
import UploaderTrack from '../../organisms/Uppy/src/UploaderTrack'
import { isAndroid, isDesktop, isIOS } from 'react-device-detect'

const Controls = () => {
	const { uuid } = useParams()
	const navigate = useNavigate()

	return (
		<NavBar>
			<HStack spacing={['4%', '1rem']}>
				<BackButton left onClick={() => navigate(`/event/${uuid}/customize`)} />
			</HStack>
		</NavBar>
	)
}

const UploadSong = () => {
	useScrollTopOnMount()
	const { uuid } = useParams()
	const { event } = useEventContext()
	const navigate = useNavigate()

	const handleBack = () => navigate(`/event/${uuid}/customize`)

	let myDevice = []
	if (isDesktop) {
		myDevice = {
			name: 'Desktop/Laptop',
			icon: '🖥',
			option: { name: 'Mobile Device', icon: '📱' },
		}
	} else if (isAndroid) {
		myDevice = {
			name: 'Android Device',
			icon: '📱',
			option: { name: 'Desktop/Laptop', icon: '🖥' },
		}
	} else if (isIOS) {
		myDevice = {
			name: 'iPhone',
			icon: '📱',
			option: { name: 'Desktop/Laptop', icon: '🖥' },
		}
	} else {
		myDevice = { name: 'Unknown Device', option: { name: 'Desktop/Laptop', icon: '🖥' } }
	}

	if (event.id) {
		return (
			<>
				<Controls />

				<Container maxW="container.md" py="1rem" variant="main">
					<Box my="2rem" textAlign={['left', 'center']} mx={['1rem', 0]}>
						<Heading as="h2" size="xl" variant="hero">
							Upload Options
						</Heading>

						<Text>
							You can also upload using a <strong>{myDevice.option.name}</strong> {myDevice.option.icon}
						</Text>
					</Box>

					<Card
						alignItems="center"
						variant="main"
						mb="2rem"
						textAlign="center"
						mx={['1rem', 0]}
						borderRadius={['xl']}>
						<UploaderTrack event={event} handleClose={handleBack} maxNumberOfFiles={1} />
					</Card>

					<FooterBase />
				</Container>
			</>
		)
	}

	return <Spinner thickness="4px" speed="0.45s" emptyColor="gray.200" color="blue.500" size="md" />
}

export default UploadSong
