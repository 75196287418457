import { logCustomEvent } from '@vidday/utils'

/**
 * Method that takes the name of the file being uploaded by the user,
 * and strip all spaces, replacing them with an underscore.
 * @param {object} file
 * @returns  {string}
 */
export const getCleanFileName = (file) => {
	const modifiedFile = Object.assign({}, file)
	modifiedFile.name = file.name.replace(/ /g, '_')
	return modifiedFile
}

/**
 * Method that allow extracting the source of Upload
 * @param {object} file
 * @returns {string}
 */
export const getSource = (file) => {
	if (file.source == 'react:Dashboard') {
		return 'Upload'
	} else if (file?.remote?.body?.fileId?.includes('tenor')) {
		return 'Gif'
	} else if (file?.remote?.body?.fileId?.includes('unsplash')) {
		return 'Unsplash'
	} else {
		return file.source
	}
}

/**
 *  Method that returns an object with the file props,
 * common to all uploaders. (Base props)
 * @param {object} file
 * @returns {object}
 */
export const formatBaseFile = (file, event) => {
	/** Create our inital upload file */
	let upload = {}

	/** Attach the event ID */
	upload.eventId = `${event.id}`

	/** Set common attributes */
	upload.uploadUuid = file.meta.uploadUuid
	upload.mediaUuid = file.meta.mediaUuid
	upload.name = `${file.meta.storeName.replace(`${file.extension}`, '').replace('.', '')}` // add mediaUuid, remove extention, second scrape replace for accepted chars
	upload.mimeType = file.type // one of 'image/jpeg', 'video/mp4' etc.
	upload.key = `${event.uuid}/${file.meta.storeName.replace(`${file.extension}`, '').replace('.', '')}` // add event UUID, add mediaUuid, remove extention, second scrape replace for accepted chars
	upload.sourceExt = `.${file.extension}`
	upload.medium = getSource(file)
	upload.sourceFilename = file.data.name

	return upload
}

/**
 * Method that send logs to GTM regarding the new file being created.
 * @param {object} data
 */
export const logGTMTracking = (data) => {
	// GTM Tracking
	// What media are we handling most?
	logCustomEvent({
		action: 'upload_media_format',
		category: 'events',
		label: data.extension ? `${data.extension}` : `${data.type}`,
		identifier: `${data.meta.eventId}`,
	})
	logCustomEvent({
		action: 'upload_media_medium',
		category: 'events',
		label: data.source == 'react:Dashboard' ? 'Upload' : `${data.source}`,
		identifier: `${data.meta.eventId}`,
	})
	// image
	if (/^image/i.test(data.type)) {
		logCustomEvent({
			action: 'upload_media',
			category: 'events',
			label: 'uploaded_image',
			identifier: `${data.meta.eventId}`,
		})
	}
	// video
	else if (/^video/i.test(data.type)) {
		logCustomEvent({
			action: 'upload_media',
			category: 'events',
			label: 'uploaded_video',
			identifier: `${data.meta.eventId}`,
		})
	}
	// audio
	else if (/^audio/i.test(data.type)) {
		logCustomEvent({
			action: 'upload_media',
			category: 'events',
			label: 'uploaded_audio',
			identifier: `${data.meta.eventId}`,
		})
	}
	// unkown
	else {
		logCustomEvent({
			action: 'upload_media',
			category: 'events',
			label: 'uploaded_media_unknown',
			identifier: `${data.meta.eventId}`,
		})
	}
}

/*!
 * Pintura Image Editor 8.52.3
 * (c) 2018-2023 PQINA Inc. - All Rights Reserved
 * License: https://pqina.nl/pintura/license/
 */
/* eslint-disable */

// export const useEditorWithUppy = (openEditor, editorOptions = {}, getUppyRef) => {
// 	const queue = [];

// 	const canEditFile = (file) => {
// 			if (file.isRemote) return false;
// 			if (!(file.data instanceof Blob)) return false;
// 			return /^image/.test(file.type) && !/svg/.test(file.type);
// 	};

// 	const editNextFile = () => {
// 			const next = queue[0];
// 			if (next) next();
// 	};

// 	const queueFile = (file) => {
// 			queue.push(function () {
// 					const editor = openEditor({
// 							...editorOptions,
// 							src: file.data,
// 					});

// 					editor.on('hide', () => {
// 							// Remove this item from the queue
// 							queue.shift();

// 							// Edit next item in queue
// 							editNextFile();
// 					});

// 					editor.on('processerror', console.log);

// 					editor.on('process', ({ dest }) => {
// 							// Don't add file if cancelled
// 							if (!dest) return;

// 							// add the modified file
// 							const uppy = getUppyRef ? getUppyRef() : window['uppy'];

// 							// can't
// 							dest.__handledByEditor = true;

// 							// add the file
// 							uppy.addFile({
// 									data: dest,
// 									name: dest.name,
// 									type: dest.type,
// 									size: dest.size,
// 							});
// 					});
// 			});

// 			// If this is first item, let's open the editor immmidiately
// 			if (queue.length === 1) editNextFile();
// 	};

// 	return function (file) {
// 			if (file.data.__handledByEditor || !canEditFile(file)) return true;

// 			// edit first, then add manually
// 			queueFile(file);

// 			// can't add now, we have to wait for editing to finish
// 			return false;
// 	};
// }
