import { useState } from 'react'
import { useAuthContext } from '../../../../contexts/AuthProvider'
import { useTemporaryUserContext } from '../../../../contexts/TemporaryUserProvider'

/**
 * 	This hook aim to retrieve the user id to use as the initiator,
 * 	that will be attached to the upload object.
 * @returns {string}
 */
const useContributorId = () => {
	const { userId } = useAuthContext()
	const temporaryUserAccount = useTemporaryUserContext()

	const [contributorId] = useState(
		userId ? userId : temporaryUserAccount && temporaryUserAccount.id ? temporaryUserAccount.id : null
	)
	return contributorId
}

export default useContributorId
