import { useState } from 'react'
import randomBytes from 'randombytes'

const useGroupMediaUUID = () => {
	const [groupMediaUuid] = useState(randomBytes(16).toString('hex'))

	return groupMediaUuid
}

export default useGroupMediaUUID
