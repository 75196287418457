import React, { useState } from 'react'
import { isMobile } from 'react-device-detect'

const defaultMobile = ['Audio', 'GoogleDrive', 'Dropbox', 'Url']
const defaultDesktop = ['Webcam', 'Audio', 'GoogleDrive', 'Dropbox', 'Url']

const usePluggins = (mobile = defaultMobile, desktop = defaultDesktop) => {
	const [plugins] = useState(isMobile ? mobile : desktop)

	return plugins
}

export default usePluggins
